<template>

    <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
            S.
          <span class="badge badge-warning ">{{numero_solicitudes}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="height: auto; max-height: 200px; overflow-x: hidden; min-width: 400px;">
          <div class="dropdown-divider"></div>
          <ListaSolicitudes :solicitudes="solicitudes"/>
        </div>
    </li>
</template>
<script>
import ListaSolicitudes from './SolicitudesLista.vue';


export default({
    components:{
        ListaSolicitudes
    },
    props: ['solicitudes','numero_solicitudes'],
    data() {
        return {
   
        }
    },
    methods: {
    
    },
    mounted() {
    }
})
</script>
