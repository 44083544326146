<template>
    <li class="nav-item dropdown ">
        <a class="nav-link" ref="dropdownToggle" data-toggle="dropdown" href="#">
            <i class="fa fa-phone" 
            :class="{
            'bg-success': isLlamadaEstablecida, 
            'bg-white': !isLlamadaActiva,
            'blinking-bg': isLlamadaEntrante
            }" style="color: white; padding: 5px; border-radius: 50%; display: inline-block;"></i>
            <span v-if="payload" class="badge badge-danger"></span>
        </a>
        <div  ref="dropdownMenu" class="dropdown-menu dropdown-menu-right" style="height: auto; overflow-x: hidden; min-width: 350px;">
            <WebphoneExtension />
            <WebphoneAgenda :extensiones="extensiones"/>
            <div class="dropdown-divider"></div>
            <!-- Mostrar el infobox de la llamada entrante -->
            <WebphoneLlamadaEntrante 
                v-if="estadoLlamada === 'llamada_entrante' || estadoLlamada === 'llamada_establecida'"
                :payload="callPayload"
            />
        </div>
    </li>
</template>
<script>

import $ from 'jquery'
import WebphoneLlamadaEntrante from './WebphoneLlamadaEntrante.vue';
import WebphoneExtension from './WebphoneExtension.vue';
import WebphoneAgenda from './WebphoneAgenda.vue';


export default({
    props: ['payload'],
    components: {
        WebphoneLlamadaEntrante,
        WebphoneExtension,
        WebphoneAgenda
    },
    data() {
        return {
            estadoLlamada: null, // Puede ser 'llamada_entrante', 'llamada_establecida' o null
            callPayload: null,   // Almacena el payload relacionado con la llamada
            extensiones: [], 
        }
    },
    computed: {
        // Si la llamada es entrante, parpadea
        isLlamadaEntrante() {
            return this.estadoLlamada === 'llamada_entrante';
        },
        // Si la llamada está establecida, el fondo es verde
        isLlamadaEstablecida() {
            return this.estadoLlamada === 'llamada_establecida';
        },
        // Si hay una llamada activa (entrante o establecida)
        isLlamadaActiva() {
            return this.estadoLlamada === 'llamada_entrante' || this.estadoLlamada === 'llamada_establecida';
        }
    },
    watch: {
        payload(newPayload) {
        if (newPayload) {
            this.manejarNotificacion(newPayload);
            this.actualizarEstadoLlamada(newPayload);
            this.actualizarExtensiones(newPayload); 
        }
        }
    },
    methods: {
        manejarNotificacion(newPayload) {
            const dropdownToggle = this.$refs.dropdownToggle;
            const dropdownMenu = this.$refs.dropdownMenu;

            if (newPayload.data.tipo_notificacion === 'llamada_entrante' || newPayload.data.tipo_notificacion === 'llamada_establecida') {
                // Mostrar el dropdown
                $(dropdownToggle).dropdown('show');

                // Evitar que se cierre automáticamente
                $(dropdownMenu).on('hide.bs.dropdown', function (e) {
                e.preventDefault();
                });

            } else if (newPayload.data.tipo_notificacion === 'llamada_finalizada') {
                // Cerrar el dropdown manualmente
                $(dropdownToggle).dropdown('hide');

                // Permitir que se cierre el dropdown después de llamada finalizada
                $(dropdownMenu).off('hide.bs.dropdown'); // Remover la prevención
            }
        },
        actualizarEstadoLlamada(newPayload) {
            const tipoNotificacion = newPayload.data.tipo_notificacion;
            if (tipoNotificacion === 'llamada_entrante' || tipoNotificacion === 'llamada_establecida') {
                this.estadoLlamada = tipoNotificacion;
                this.callPayload = newPayload; // Almacenar el payload de la llamada
            } else if (tipoNotificacion === 'llamada_finalizada') {
                this.estadoLlamada = null;
                this.callPayload = null; // Limpiar el payload de la llamada
            }
            // Para otras notificaciones, no cambiamos estadoLlamada ni callPayload
        },
        actualizarExtensiones(newPayload) {
            if (newPayload.data && newPayload.data.extensiones) {
                try {
                    this.extensiones = JSON.parse(newPayload.data.extensiones);
                } catch (error) {
                    console.error('Error al parsear extensiones:', error);
                    // Mantener las extensiones existentes en caso de error
                }
            }
            // Si no hay datos de extensiones, no modificar this.extensiones
        },
    },
    mounted() {
    }
})
</script>
<style scoped>
    @keyframes blinkingBackground {
    0% { background-color: white; }
    50% { background-color: green; }
    100% { background-color: white; }
    }

    .blinking-bg {
    animation: blinkingBackground 1s infinite;
    }
</style>