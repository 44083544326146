<template>

  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
      <a class="navbar-brand" href="#">
        <img :src="logo"  height="36">
      </a>     
      <li class="nav-item d-none d-sm-inline-block">
        <a href="#" @click="iraescriorio()" class="nav-link">{{ $t('Escritorio') }}</a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="#" @click="urlantigua()" class="nav-link">Version antigua</a>
      </li>

    </ul>
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" title="Extender">
          <i @click="mostrar()" title="Extender" class="fa fa-eye"></i>
        </a>
      </li>
      <MenuSupAsignaciones :asignaciones="asignaciones" :numero_asignaciones="numero_asignaciones" />
      <MenuSupSolicitudes :solicitudes="solicitudes" :numero_solicitudes="numero_solicitudes" />
      <MenuSupTrabajosNoRevisados :trabajos="trabajos" :numero_trabajos="numero_trabajos" />
      <MenuSupAvisos />  
      <buscadorserviciosmenu></buscadorserviciosmenu>
      <a href="#" class="nav-link" @click="abrirchats()"><i class="fab fa-weixin"></i>&nbsp;<span class="badge badge-danger bg-purple">{{ chats
          }}</span></a>
      <a href="#" class="nav-link" @click="activaralarmas()"><i class="far fa-sticky-note"></i>&nbsp;<span class="badge badge-danger ">{{ longitud
          }}</span></a>
      <MenuSupCorreos />
      <MenuSupWebphone v-if="hasExtension" :payload="payload" />
      <LocaleChanger />
      <MenuUsuario />
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
  <form id="TheForm" method="get" :action="'https://' + direccionglobal" target="TheWindow">
  </form>
</template>
<script>
import "admin-lte/plugins/flag-icon-css/css/flag-icons.css";
import LocaleChanger from './LocaleChanger.vue';
import MenuUsuario from './pages/menus/superior/usuario.vue';
import buscadorserviciosmenu from "./pages/menus/superior/BuscadorServicios/buscadorserviciosmenu.vue";
import MenuSupAsignaciones from './pages/menus/superior/asignaciones/AsignacionesMenu.vue';
import MenuSupSolicitudes from './pages/menus/superior/solicitudes/SolicitudesMenu.vue';
import MenuSupTrabajosNoRevisados from './pages/menus/superior/TrabajosNoRevisados/TrabajosNoRevisadosMenu.vue';
import MenuSupAvisos from './pages/menus/superior/avisos/AvisosMenu.vue';
import MenuSupCorreos from './pages/menus/superior/correos/CorreosMenu.vue';
import MenuSupWebphone from './pages/menus/superior/webphone/WebphoneMenu.vue';

import { PwgsApi } from "../services/PwgsApi";
export default ({
  props: ['longitud', 'trabajos', 'numero_trabajos', 'solicitudes', 'numero_solicitudes', 'asignaciones','numero_asignaciones', 'payload', 'chats'],
   data() {
    return {
      direccionglobal: '',
      hasExtension: false
    }
  },
  methods: {
    abrirchats(){
      this.$router.push({
          name: 'Chat',
      });
    },
    activaralarmas(){
      this.$parent.togglevisible();
    },
    mostrar() {
      this.$parent.mostrarextendido();
    },
    urlantigua() {
      //document.getElementById('usuario').value = localStorage.getItem('nombreusuario');
      //document.getElementById('contrasena').value = localStorage.getItem('pwd');
      window.open('', 'TheWindow');
      document.getElementById('TheForm').submit();
    },
    async cargarlogo() {
      const api = new PwgsApi();
      this.logo = await api.get('logo');
    },
    iraescriorio() {
      localStorage.setItem("tipobuscador", "defecto");

      this.$router.push({
        name: 'Escritorio'
      });

    }
    
  },
  setup() {   
    return;
  },
  components: {
    LocaleChanger,
    MenuUsuario,
    MenuSupAsignaciones,
    MenuSupSolicitudes,
    MenuSupTrabajosNoRevisados,
    MenuSupAvisos,
    MenuSupCorreos,
    buscadorserviciosmenu,
    MenuSupWebphone  
  },
  created() {
    this.cargarlogo();
  },
  mounted() {
    // Verificar si existe un valor en localStorage para 'extension'
    const extension = localStorage.getItem('extension');
    if (extension) {
      this.hasExtension = true; // Solo mostrar MenuSupWebphone si hay una extensión
    }
    this.direccionglobal = localStorage.getItem('direccion');
  } 
})
</script>