<script setup lang="ts"/>

<template>
    <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="flag-icon " :class="icon_language_selected"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right p-0">
          <a href="#" class="dropdown-item"  v-for="(lang, i) in langs" :key="`Lang${i}`" @click="updateLanguage(lang.code)">
            <i class="flag-icon mr-2" :class="lang.icon"></i> {{ lang.text }}
          </a>
          
        </div>
      </li>
    
    
</template>

<script>
export default {
    name: "locale-changer",
    data() {
        return {
            langs: [
                { code: "es", text: "Español", icon: "flag-icon-es" },
                { code: "en", text: "English", icon: "flag-icon-gb" },
                { code: "cat", text: "Valencià/Català", icon: "flag-icon-es-ct" },
            ],
            icon_language_selected: "flag-icon-es"
        };
    },
    methods: {
        updateLanguage(code) {
            localStorage.setItem("locale", code);
            window.location.reload();
        },
        iconLanguageSelected() {
            let item  = this.langs.find((item) => item.code == localStorage.getItem("locale"))
            this.icon_language_selected = item.icon;
        }
    },
    mounted() {
        if (localStorage.getItem("locale")) {
            this.$i18n.locale = localStorage.getItem("locale");
        } else {
            localStorage.setItem("locale", this.$i18n.locale);
        }
        this.iconLanguageSelected();
        
    },
};

</script>