<template>

    <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
            TnR.
          <span class="badge badge-success ">{{numero_trabajos}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="height: auto; max-height: 200px; overflow-x: hidden; min-width: 400px;">
          <div class="dropdown-divider"></div>
          <ListaTrabajos :trabajos="trabajos"/>
        </div>
    </li>
</template>
<script>
import ListaTrabajos from './TrabajosNoRevisadosLista.vue';


export default({
    components:{
        ListaTrabajos
    },
    props: ['trabajos','numero_trabajos'],
    data() {
        return {
       
        }
    },
    methods: {
        
    },
   
})
</script>
