<template>
    <div class="small-box bg-warning margen">
        <div class="inner">
            <h3>{{ extension }}</h3>
        </div>
        <div class="icon">
            <i class="fas fa-user"></i>
        </div>
    </div>
</template>
<script>
  export default {
    props: {
        payload: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
        extension: null // Inicializamos el valor de la extensión
        };
    },
    mounted() {
        // Obtenemos el valor de localStorage cuando el componente se monta
        this.extension = localStorage.getItem('extension');
    }
  }
  </script>
  <style scoped>
  .margen {
    padding: 10px;
    margin: 10px;
  }
  </style>